<template>
  <div style="padding: 15px; margin-bottom: 60px">
    <div>
      {{ $t('operate') }}
    </div>
  </div>
</template>

<script>
export default {
  components: {
    [Image.name]: Image,
  },
  data() {
    return {
      ua: null,
    };
  },
  mounted() {
    const navi = window.navigator;
    const ua = navi.userAgent;
    this.ua = ua;
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.shipping-item {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  color: #969799;
  font-size: 14px;
  padding: 10px 0px;
}

.shipping-icon-container {
  position: absolute;
  top: 25px;
  left: -15px;
  z-index: 1;
  font-size: 12px;
  line-height: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.shipping-step-line {
  position: absolute;
  background-color: #eb6a68;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  top: 16px;
  left: -15px;
  width: 1px;
  height: 100%;
}

.shipping-icon {
  width: 30px;
  height: 30px;
  border: solid 1px #eb6a68;
  border-radius: 20px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: white;
}

.shipping-title {
  color: #555;
  padding-left: 10px;
}
</style>